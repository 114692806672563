import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TopBanner from "../components/topBanner"

const OutreachProject = () => (
  <Layout>
    <SEO title="Outreach Project" />
    <TopBanner />
    <div
      className="fill-height-or-more"
      style={{
        margin: `auto`,
        maxWidth: `80%`,
      }}
    >
      <h1
        style={{
          marginTop: `2em`,
          color: `#ff5a5f`,
          textTransform: `uppercase`,
        }}
      >
        Outreach Project
      </h1>
      <h2>Please bring a backpack full of these school supplies:</h2>

      <ul>
        <li>1 package markers</li>
        <li>1 box crayons</li>
        <li>1 package colored pencils</li>
        <li>24 pre-sharpened pencils</li>
        <li>1 manual pencil sharpener</li>
        <li>1 bottle glue</li>
        <li>1 package glue sticks</li>
        <li>1 package highlighters</li>
        <li>1 package blue/black pens</li>
        <li>1 package red pens</li>
        <li>1 pair rounded scissors</li>
        <li>4 folders with brads</li>
        <li>3 spiral notebooks</li>
        <li>1 2” 3-ring binder</li>
        <li>1 package lined paper</li>
        <li>1 pencil pouch</li>
        <li>1 watercolor set</li>
      </ul>

      <h3 style={{ color: `#ff5a5f`, marginBottom: `3em` }}>
        Backpacks are due back to ANX on <b>July 28!</b>
      </h3>
    </div>
  </Layout>
)

export default OutreachProject
