import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "./topBanner.css"

const LinkCheck = ({ link, image }) => {
  const internal = /^\/(?!\/)/.test(link)
  if (internal) {
    console.log("Internal Link")
    return (
      <div className="topBanner">
        <Link to={link}>
          <Img fluid={image} />
        </Link>
      </div>
    )
  } else {
    console.log("EXT Link")
    return (
      <div className="topBanner">
        <a href={link}>
          <Img fluid={image} />
        </a>
      </div>
    )
  }
}

const TopBanner = ({ link, type }) => (
  <StaticQuery
    query={graphql`
      query {
        file: file(relativePath: { eq: "Thumbnail.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <LinkCheck
        link={link}
        type={type}
        image={data.file.childImageSharp.fluid}
      />
    )}
  />
)
export default TopBanner
